import { uniqueId } from 'lodash'
import { ISelectOption } from '../../types/interfaces'
import { IOrderFilters } from './types'

export const SET_ORDER_FILTERS = 'SET_ORDER_FILTERS'

export const defaultOrderFilters: IOrderFilters = {
  stock: '',
  order_status: '',
  start_date: null,
  end_date: null,
  search_text: '',
  sort_by: 'delivery_date',
  show_shipped: false,
}

export const stockStatusFilterOptions: Array<ISelectOption> = [
  { value: 'green', label: 'Green', id: uniqueId() },
  { value: 'yellow', label: 'Yellow', id: uniqueId() },
  { value: 'red', label: 'Red', id: uniqueId() },
]

export const sortByOptions: Array<ISelectOption> = [
  { value: 'delivery_date', label: 'Delivery Date', id: 'delivery_date' },
  { value: 'pallet_count', label: 'Pallet Count', id: 'pallet_count' },
]
