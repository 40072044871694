import React from 'react';
import { createContext, useReducer, useContext } from 'react'
import { IShippingState, IOrderFilters } from './types'
import { defaultOrderFilters, SET_ORDER_FILTERS } from './constants';
import shippingReducer from './ShippingReducer'

export const initialState: IShippingState = {
  orderFilters: defaultOrderFilters,
  setOrderFilters: () => {},
}

export const ShippingContext = createContext(initialState)

export const useShippingContext = () => useContext(ShippingContext)

export interface IShippingProviderProps {
  children?: React.ReactNode
}

export const ShippingProvider = (props: IShippingProviderProps): JSX.Element => {
  const { children } = props

  const [state, dispatch] = useReducer(shippingReducer, initialState)

  const setOrderFilters = async (orderFilters: IOrderFilters) => {
    dispatch({
      type: SET_ORDER_FILTERS,
      payload: orderFilters,
    })
  }

  const value = {
    ...state,
    setOrderFilters,
  }

  return (
    <ShippingContext.Provider value={value}>{children}</ShippingContext.Provider>
  )
}