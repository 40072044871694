import {
  SET_ORDER_FILTERS,
} from './constants'

import { IShippingAction, IShippingState } from './types'

export default function shippingReducer(
  state: IShippingState,
  action: IShippingAction,
): IShippingState {
  const payload = action.payload
  switch (action.type) {
    case SET_ORDER_FILTERS:
      return {
        ...state,
        orderFilters: payload,
      }
    default:
      return state
  }
}
